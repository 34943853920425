import revive_payload_client_eZFquwVB27 from "/home/bgrand/Documents/Projects/live-display/node_modules/.pnpm/nuxt@3.15.4_@electric-sql+pglite@0.2.16_@parcel+watcher@2.5.0_@types+node@22.9.0_db0@0.2.1_@e_gsfqige7zcegquxcuejy554se4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_8ovZ15xgFi from "/home/bgrand/Documents/Projects/live-display/node_modules/.pnpm/nuxt@3.15.4_@electric-sql+pglite@0.2.16_@parcel+watcher@2.5.0_@types+node@22.9.0_db0@0.2.1_@e_gsfqige7zcegquxcuejy554se4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_x1ofPMobw2 from "/home/bgrand/Documents/Projects/live-display/node_modules/.pnpm/nuxt@3.15.4_@electric-sql+pglite@0.2.16_@parcel+watcher@2.5.0_@types+node@22.9.0_db0@0.2.1_@e_gsfqige7zcegquxcuejy554se4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_OEV2XddXjW from "/home/bgrand/Documents/Projects/live-display/node_modules/.pnpm/nuxt-site-config@3.0.6_magicast@0.3.5_vite@6.1.0_@types+node@22.9.0_jiti@2.4.2_sass@1.83.1_te_3xgak3rtgxnb2vmecyeqr26qzi/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_PIxs2qlSjP from "/home/bgrand/Documents/Projects/live-display/node_modules/.pnpm/nuxt@3.15.4_@electric-sql+pglite@0.2.16_@parcel+watcher@2.5.0_@types+node@22.9.0_db0@0.2.1_@e_gsfqige7zcegquxcuejy554se4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_OYR10dwK0E from "/home/bgrand/Documents/Projects/live-display/node_modules/.pnpm/nuxt@3.15.4_@electric-sql+pglite@0.2.16_@parcel+watcher@2.5.0_@types+node@22.9.0_db0@0.2.1_@e_gsfqige7zcegquxcuejy554se4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_gIVDkQKbku from "/home/bgrand/Documents/Projects/live-display/node_modules/.pnpm/nuxt@3.15.4_@electric-sql+pglite@0.2.16_@parcel+watcher@2.5.0_@types+node@22.9.0_db0@0.2.1_@e_gsfqige7zcegquxcuejy554se4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_2M6oeqac2t from "/home/bgrand/Documents/Projects/live-display/node_modules/.pnpm/nuxt@3.15.4_@electric-sql+pglite@0.2.16_@parcel+watcher@2.5.0_@types+node@22.9.0_db0@0.2.1_@e_gsfqige7zcegquxcuejy554se4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/home/bgrand/Documents/Projects/live-display/public/.nuxt/components.plugin.mjs";
import prefetch_client_bNbiYH4I1j from "/home/bgrand/Documents/Projects/live-display/node_modules/.pnpm/nuxt@3.15.4_@electric-sql+pglite@0.2.16_@parcel+watcher@2.5.0_@types+node@22.9.0_db0@0.2.1_@e_gsfqige7zcegquxcuejy554se4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import firebase_MrRCoKgj2U from "/home/bgrand/Documents/Projects/live-display/shared/plugins/firebase.ts";
import airbrake_H5MbEqRsNX from "/home/bgrand/Documents/Projects/live-display/shared/plugins/airbrake.ts";
import aos_u8RxmzeSAw from "/home/bgrand/Documents/Projects/live-display/public/plugins/aos.ts";
import clarity_FoYTnyX1Oc from "/home/bgrand/Documents/Projects/live-display/public/plugins/clarity.ts";
export default [
  revive_payload_client_eZFquwVB27,
  unhead_8ovZ15xgFi,
  router_x1ofPMobw2,
  _0_siteConfig_OEV2XddXjW,
  payload_client_PIxs2qlSjP,
  navigation_repaint_client_OYR10dwK0E,
  check_outdated_build_client_gIVDkQKbku,
  chunk_reload_client_2M6oeqac2t,
  components_plugin_KR1HBZs4kY,
  prefetch_client_bNbiYH4I1j,
  firebase_MrRCoKgj2U,
  airbrake_H5MbEqRsNX,
  aos_u8RxmzeSAw,
  clarity_FoYTnyX1Oc
]