// import AOS from 'aos'

// TODO: Add AOS data attributes to the elements to be animated.
export default defineNuxtPlugin(() => {
  if (!import.meta.client) {
    return
  }

  // AOS.init({
  //   easing: 'ease-in-out-quad',
  //   duration: 800,
  //   offset: 200,
  //   delay: 300,
  //   once: true
  // })
})
